let origin = "https://future.zhuneng.cn";
//提交心愿
const postWishUrl = `/gateway/hc-govern/miniapi/wishWall/add`;
//我的心愿
const myWishUrl = `/gateway/hc-govern/miniapi/wishWall/mineList`;
//心愿详情
const wishDetailUrl = `/gateway/hc-govern/miniapi/wishWall/detailInfo`;
//心愿墙
const wishWallUrl = `/gateway/hc-govern/miniapi/wishWall/list`;

export { postWishUrl, myWishUrl, wishDetailUrl, wishWallUrl };
